import React, { useContext, useEffect, useState } from "react";
import ReflectHeading2 from "../../CommonComponent/Typography/ReflectHeading2";
import useAnimations from "../../../Auth/useAnimations";
import bgImage from "../../../Images/FormsPage/backgroundImages/general_info.png";
import mobile_bgImage from "../../../Images/FormsPage/backgroundImages/general_info_mobile_bg.png";
import CircularProgress from "@mui/material/CircularProgress";
import {} from "../Static/style.css";
import { useParams, useNavigate } from "react-router-dom";

import { LanguageContext } from "../../../Context/Language";

function createMarkup(string) {
	return { __html: string };
}

export const Index = () => {
	const url = useParams();
	const { languageData } = useContext(LanguageContext);

	const [load, setLoad] = useState(false);
	const [blogData, setBlogData] = useState();
	const [error, setError] = useState(false);

	const validProductionAdminUrl = function (url = "") {
		if (process.env.REACT_APP_NODE_ENV === "TEST") {
			return `http://localhost:3030${url}`;
		} else {
			return `https://admin.ventadirekta.mx${url}`;
		}
	};

	useEffect(() => {
		setLoad(false);
		getData();
	}, []);

	const getData = async () => {
		try {
			const res = await fetch(validProductionAdminUrl(`/api/v1/blogs/${url.id}`));
			const blog_data = await res.json();

			setBlogData(blog_data);

			setError(false);
		} catch (e) {
			console.log(e);
			setError(true);
		}
		setLoad(true);
	};

	if (load) {
		let blog = JSON.parse(blogData.articles);

		return (
			<div className="default-page">
				<div className="default-page-section blog">
					<img src={blog.image.src} className="blog-banner" alt="blog" />
					<ReflectHeading2 boldText={blog.title} />
					<div className="default-page-p" dangerouslySetInnerHTML={createMarkup(blog.body_html)}></div>
				</div>

				<picture className="sectionBgImage">
					<source media="(max-width:575px)" srcSet={mobile_bgImage} />
					<img src={bgImage} alt="Flowers" />
				</picture>
			</div>
		);
	} else {
		return (
			<div style={{ width: "100%", height: "100vh", backgroundColor: "#fff", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
				<CircularProgress style={{ color: "#000" }} />
			</div>
		);
	}
};
