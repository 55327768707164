import React from 'react'
import MainButton from '../../../CommonComponent/Button/MainButton'
import { motion } from 'framer-motion'
import useAnimations from '../../../../Auth/useAnimations'
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
// --images--
import img1 from '../../../../Images/WantToSellPage/ForthSectionGalleryImg2.webp'
import img2 from '../../../../Images/WantToSellPage/ForthSectionGalleryImg3.webp'
import { LazyLoadImage } from 'react-lazy-load-image-component';
//--elements--
import starElement from '../../../../Images/elements/star.svg'
import downArrow from '../../../../Images/elements/downArrow.svg'
import pentagon from '../../../../Images/elements/pentagon.svg'
import blockes from '../../../../Images/elements/blockes.svg'

// --bg lines--
import linesBg from '../../../../Images/WantToSellPage/secondSectionBglines.svg'


export default function SecondSection() {
    const navigate = useNavigate()
    const { textAnimation } = useAnimations()
    return (
        <div className="section secondSection">
            <div className="container secondContainer">
                <div className="boxes">
                    {/* -- */}
                    <div className="box box1">
                        <motion.p variants={textAnimation} initial="offscreen" whileInView="onscreen" className="heading"><span>¿Cómo</span> funciona?</motion.p>
                        <motion.p variants={textAnimation} initial="offscreen" whileInView="onscreen" className="subHeading">En 5 minutos podrás crear tu tienda</motion.p>
                        <motion.p variants={textAnimation} initial="offscreen" whileInView="onscreen" className="pera">Completa el formulario con todos tus datos, el nombre que escogiste para
                            tu tienda, los colores que van mejor con tu estilo y las categorías que
                            deseas vender y listo. Verificamos tus datos y tendrás lista tu página web
                            para compartir con tus conocidos y empezar a vender.</motion.p>
                        <MainButton text="CREAR MI TIENDA" action={() => navigate('/form')} startIcon={<EastIcon />} />
                    </div>
                    {/* -- */}
                    <div className="box box2">
                        <LazyLoadImage src={img1} effect='blur' />
                    </div>
                    <motion.div variants={textAnimation} initial="offscreen" whileInView="onscreen" className="box box3">
                        {/* <p className="pera">Maecenas commodo sem ex. Morbi
                            venenatis erat accumsan, convallis mi non,
                            posuere dolor. Pellentesque et posuere ante,
                            quis blandit urna. Vestibulum sollicitudin
                            molestie neque quis convallis.</p> */}
                    </motion.div>
                    <div className="box box4">
                        <LazyLoadImage src={img2} effect='blur' />
                    </div>
                </div>
                {/* --- */}
                <div className="elementsDiv">
                    <img src={blockes} alt="element" className="first" />
                    <img src={downArrow} alt="element" className="second" />
                </div>

                {/* --background image-- */}

                <picture className='sectionBgImage'>
                    {/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
                    <img src={linesBg} alt="bgLine" />
                </picture>

            </div>
        </div>
    )
}
