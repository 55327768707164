import { useState, useEffect, createContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {} from "./style.css"

export const LanguageContext = createContext();

export function LanguageContextProvider(props) {
	const [load, setLoad] = useState(false);
	const [languageData, setLanguageData] = useState();
	const [firstLoad, setFirstLoad] = useState(false);
	const [error, setError] = useState(false);
	const [language, setLanguage] = useState("es-CO");
	const lang_value = { languageData, setLanguage };

	useEffect(() => {
		setLoad(false);
		getData(language);
	}, [language]);

	const validProductionAdminUrl = function (url = "") {
		if (process.env.REACT_APP_NODE_ENV === "TEST") {
			return `http://localhost:3030${url}`;
		} else {
			return `https://admin.ventadirekta.mx${url}`;
		}
	};

	const getData = async (language) => {

		try {
			const res = await fetch(validProductionAdminUrl(`/api/v1/landing/${language}`));
			const language_result = await res.json();

			setLanguageData(language_result);

			setError(false);
			setFirstLoad(true);
		} catch (e) {
			console.log(e);
			setError(true);
		}
		setLoad(true);
	};

	if (load && language && error === false) {
		return <LanguageContext.Provider value={lang_value}>{props.children}</LanguageContext.Provider>;
	} else if (load && language && error === true) {
		return (
			<LanguageContext.Provider value={lang_value}>
				{error ? (
					<div className="context-error">
						<p>Parece que no tienes internet u ocurrió un error</p>
						<CircularProgress style={{ color: "#fff" }} />
					</div>
				) : (
					""
				)}
				{props.children}
			</LanguageContext.Provider>
		);
	} else {
		return (
			<div className="landing-pre-loader-div">
				<div className="landing-pre-loader">
					<img src="https://ventadirekta.com/assets/images/logo/logo-ventadirekta.webp" alt="" />
				</div>
			</div>
		);
	}
}
