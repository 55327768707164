import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Input_Label from './Input_Label';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Select_input({ label, placeholder, action, name, options, optionTitle, ...props }) {

    const defaultProps = {
        options: options,
        getOptionLabel: (option) => option[optionTitle],
    };

    return (

        <FormControl variant="standard" sx={{ width: "100%" }}>
            <Input_Label label={label} />
            <Autocomplete
                {...defaultProps}
                popupIcon={<ExpandMoreIcon sx={{ color: "var(--one-color)" }} />}
                renderInput={(params) => (
                    <TextField {...params} required={props.required} name={name} placeholder={placeholder} variant="standard" />
                )}



            />
        </FormControl>

    );
}


// ----demo data----
const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    {
        title: 'The Lord of the Rings: The Return of the King',
        year: 2003,
    },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    {
        title: 'The Lord of the Rings: The Fellowship of the Ring',
        year: 2001,
    },
    {
        title: 'Star Wars: Episode V - The Empire Strikes Back',
        year: 1980,
    },
    { title: 'Forrest Gump', year: 1994 },
];