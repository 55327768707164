import React from 'react'
import ReflectHeading from '../../../CommonComponent/Typography/ReflectHeading'
// --
import mobile_bgImage from '../../../../Images/HomePage/CompanyLogos/mobileBg.png'

//compnies logos
import img1 from '../../../../Images/HomePage/CompanyLogos/logo-bling-shapers.webp'
import img2 from '../../../../Images/HomePage/CompanyLogos/logo-mariae-fajas.webp'
import img3 from '../../../../Images/HomePage/CompanyLogos/logo-uplady.webp'
import img4 from '../../../../Images/HomePage/CompanyLogos/logo-fajas-salome.webp'
import img5 from '../../../../Images/HomePage/CompanyLogos/logo-sonryse.webp'
import img6 from '../../../../Images/HomePage/CompanyLogos/logo-laty-rose.webp'
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Scrollbar } from "swiper";


function CompaniesSlider() {

    // ---compine slider data---
    const compineSliderData = [
        { id: 11, link: "/", img: img1 },
        { id: 12, link: "/", img: img2 },
        { id: 13, link: "/", img: img3 },
        { id: 14, link: "/", img: img4 },
        { id: 15, link: "/", img: img5 },
        { id: 16, link: "/", img: img6 },
    ]
    return (
        <div className="compniesSliderSection">
            <div className="compniesSliderContainer container">
                <div className="heading">
                    <ReflectHeading boldText="NUESTRAS" lightText="MARCAS" />
                </div>
                {/* --logos slider-- */}
                <div className="companies_slider">

                <Swiper slidesPerView={6} spaceBetween={15} className="swiper">
                    {compineSliderData && compineSliderData.map((e) => {
                            return (
                                <SwiperSlide className="logoImg" key={e.id}>
                                    <img src={e.img} />
                                </SwiperSlide>
                            )
                        })}
                </Swiper>

                    {/* <div className="logos">
                        {compineSliderData && compineSliderData.map((e) => {
                            return (
                                <div className="logoImg" key={e.id}>
                                    <img src={e.img} />
                                </div>
                            )
                        })}
                    </div> */}
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    <source media="(max-width:575px)" srcSet={mobile_bgImage} />
                    <img src="" alt="Flowers" />
                </picture>
            </div>

        </div>
    )
}

export default CompaniesSlider