import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import useAnimations from "../../../Auth/useAnimations";
import MainButton from "../../CommonComponent/Button/MainButton";
import { useNavigate } from "react-router";

import { Box, Button, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid as MGrid } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";

import PhoneNumber_input from "../../CommonComponent/Inputs/PhoneNumber_input";
import Select_input from "../../CommonComponent/Inputs/Select_input";
import TextField_password from "../../CommonComponent/Inputs/TextField_password";
import TextField_with_icon from "../../CommonComponent/Inputs/TextField_with_icon";
import Text_input from "../../CommonComponent/Inputs/Text_input";
import TextInputMaxLength from "../../CommonComponent/Inputs/TextInputMaxLength";
import InputVerifyStore from "../../CommonComponent/Inputs/InputVerifyStore";
import CountrySelect from "../../CommonComponent/Inputs/CountrySelect";
import Heading from "../../CommonComponent/Typography/Heading";
import Text from "../../CommonComponent/Typography/Text";
import ErrorIcon from "@mui/icons-material/Error";
import bgImage from "../../../Images/FormsPage/backgroundImages/general_info.png";
import mobile_bgImage from "../../../Images/FormsPage/backgroundImages/general_info_mobile_bg.png";

import { toast } from "react-toastify";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel } from "@mui/material";

import Location_get from "../../CommonComponent/Inputs/Location_get";
import Social_option from "./Address_information_form/Social_option";
import Checkbox from "@mui/material/Checkbox";
import DescriptionIcon from "@mui/icons-material/Description";

import Modal from "@mui/material/Modal";

// --images--
import img1 from "../../../Images/FormsPage/Store_setUp_images/Rectangle 912.png";
import img2 from "../../../Images/FormsPage/Store_setUp_images/Rectangle 913.png";
import img3 from "../../../Images/FormsPage/Store_setUp_images/Rectangle 914.png";
import img4 from "../../../Images/FormsPage/Store_setUp_images/Rectangle 915.png";
import img5 from "../../../Images/FormsPage/Store_setUp_images/Rectangle 918.png";
import img6 from "../../../Images/FormsPage/Store_setUp_images/Rectangle 917.png";

import {} from "./style.css"

function Forms() {
	const navigate = useNavigate();
	const [languages, setLanguages] = useState();
	const [load, setLoad] = useState(false);

	const [ImageTheme, setImageTheme] = useState("theme_1");

	const [whatsappCheck, setWhatsappCheck] = useState(false);
	const [password, setPassword] = useState();
	const [passwordConfirmation, setPasswordConfirmation] = useState();
	const [socialOption, setsocialOption] = useState("Instagram");
	const [radioCompany, setRadioCompany] = useState();
	
	const [callBackIsValid, setCallBackIsValid] = useState("");
	const [storeNameValid, setStoreNameValid] = useState(false);

	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);

	const validProductionAdminUrl = function (url = "") {
		if (process.env.REACT_APP_NODE_ENV === "TEST") {
			return `http://localhost:3030${url}`;
		} else {
			return `https://admin.ventadirekta.mx${url}`;
		}
	};

	const handleWhatsappCheck = (event) => {
		setWhatsappCheck(event.target.checked);
	};
	const handleRadioCompany = (event) => {
		setRadioCompany(event.target.value);
	};
	const handlePassword = (event) => {
		setPassword(event.target.value);
	};
	const handlePasswordConfirmation = (event) => {
		setPasswordConfirmation(password === event.target.value ? true : false);
	};

	const getURLParameter = function (parameter_name) {
		let url_page = window.location.search.substring(1);
		let url_variables = url_page.split("&");
		for (const item_data of url_variables) {
			let parameter_url = item_data.split("=");
			if (parameter_url[0] == parameter_name) {
				return decodeURIComponent(parameter_url[1]);
			}
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoad(true);

		let data = {
			first_name: event.target.first_name.value,
			last_name: event.target.last_name.value,
			phone: event.target.phone.value.replaceAll(" ", "").replace("+", ""),
			email: event.target.email.value,
			password: event.target.password.value,
			password_confirmation: event.target.password_confirmation.value,
			language: event.target.language.value === "Spanish" ? "es-CO" : "en-US",
			card_type: "",
			card_number: "",
			card_code: "",
			card_date: "",
			card_name: "",
			card_zip_code: "",
			company: radioCompany === "yes" ? event.target.company_name.value : "",
			tax_id_eni: radioCompany === "yes" ? event.target.tax_id.value : "",
			country: event.target.country.value,
			province: event.target.province.value,
			city: event.target.city.value,
			zip_code: event.target.zip_code.value,
			address_line1: event.target.address_line1.value,
			address_line2: event.target.address_line2.value,
			store_name: event.target.store_name.value,
			store_name_initials: event.target.store_name_initials.value,
			store_phone: event.target.store_phone.value.replaceAll(" ", "").replace("+", ""),
			whatsapp: `https://api.whatsapp.com/send?phone=${event.target.store_phone.value.replaceAll(" ", "")}`,
			store_email: event.target.store_email.value,
			facebook: "",
			twitter: "",
			instagram: "",
			theme_color: ImageTheme,
			collection_style: "grid",
			social_network_advertising: socialOption === "Others" ? event.target.social_media.value : "",
			social_network_search: socialOption === "Others" ? "" : socialOption,
			record_source: getURLParameter("utm_source") ? getURLParameter("utm_source") : "web",
			content_creator_code: getURLParameter("cc_code") ? getURLParameter("cc_code") : "",
			email_consent: event.target.email_consent.checked,
			sms_consent: event.target.sms_consent.checked,
		};
		if (storeNameValid && callBackIsValid) {
			try {
				const result = await fetch(validProductionAdminUrl("/users/save-store/"), {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				});
				const submit_result = await result.json();
				console.log(submit_result);
				if (submit_result.success.show === true) {
					let type = submit_result.success.type;
					toast[type](submit_result.success.message);
				}
				if (submit_result.success.type === "success") {
					data.utm_source = getURLParameter("utm_source") ? getURLParameter("utm_source") : "web";
					data.utm_medium = getURLParameter("utm_medium") ? getURLParameter("utm_medium") : "landing";
					data.utm_campaign = getURLParameter("utm_campaign") ? getURLParameter("utm_campaign") : "";
					data.country_store = "MX";
					try {
						const s2f = await fetch(`https://s2f.2becommerce.com/vtd/form`, {
							method: "POST",
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
							body: JSON.stringify(data),
						});
						navigate('/thank-you-for-register');
					} catch (e) {
						console.log(e);
					}
				}
			} catch (e) {
				console.log(e);
			}
		} else {
			toast.error("Por favor, revisa los datos colocados");
		}

		console.log(data);
		setLoad(false);
	};

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			const phone_codes = await fetch(validProductionAdminUrl("/get/all-countries-provinces-phone-codes-react/"));
			const languages = await fetch(validProductionAdminUrl("/languages/list"));
			const collections = await fetch(validProductionAdminUrl("/collections/list-collections-store/"));
			const themes = await fetch(validProductionAdminUrl("/store-themes/list-show/"));

			const phone_codes_result = await phone_codes.json();
			const languages_result = await languages.json();
			const collections_result = await collections.json();
			const themes_result = await themes.json();

			phone_codes_result.data = phone_codes_result.data.filter((item) => item.callingCodes.find((item) => item !== "") !== undefined);
			phone_codes_result.data = phone_codes_result.data.filter((item) => item.callingCodes.find((item) => item !== "") !== undefined);
			phone_codes_result.data.map((item) => {
				item.callingCodes = item.callingCodes[0];
				delete item.currencies;
				delete item.languages;
				delete item.timezones;
			});
			// phone_codes_result.data = phone_codes_result.data.map((item) => item.callingCodes.find(item => item !== "") );
			// console.log(phone_codes_result)

			setLanguages(languages_result);

			if (phone_codes_result && languages_result && collections_result && themes_result) {
				setLoad(true);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const { pageVariants } = useAnimations();

	return (
		<motion.div className="formPage" variants={pageVariants} initial="initial" animate="visible">
			<form onSubmit={handleSubmit}>
				{load ? (
					<>
						<GeneralInfo setCallBackIsValid={setCallBackIsValid} handlePassword={handlePassword} passwordConfirmation={passwordConfirmation} handlePasswordConfirmation={handlePasswordConfirmation} languages={languages} />
						<StoreSetup setStoreNameValid={setStoreNameValid} whatsappCheck={whatsappCheck} handleWhatsappCheck={handleWhatsappCheck} ImageTheme={ImageTheme} setImageTheme={setImageTheme} />
						<CompanyData handleRadioCompany={handleRadioCompany} radioCompany={radioCompany} />
						<AdressInformation handleCloseModal={handleCloseModal} openModal={openModal} setsocialOption={setsocialOption} socialOption={socialOption} handleOpenModal={handleOpenModal} />

						{/* <TermsModal openModal={openModal} handleCloseModal={handleCloseModal} /> */}

						<div className="formPage_submitButton">
							<MainButton text="FINALIZAR" type="submit" startIcon={<EastIcon />} />
						</div>
					</>
				) : (
					<div style={{ width: "100%", height: "100vh", backgroundColor: "#fff", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
						<CircularProgress style={{ color: "#000" }} />
					</div>
				)}
			</form>
		</motion.div>
	);
}

function GeneralInfo({ handlePassword, passwordConfirmation, handlePasswordConfirmation, languages, setCallBackIsValid }) {
	return (
		<div className="section forms_section general_info_form_section">
			<div className="container">
				<div className="forms_sectionContent infoBoxContent">
					<div className="formDiv">
						<Heading text="INFORMACIÓN GENERAL" />
						<Text classText="formPage_subHeadings" text="Completa tus datos básicos y crea una cuenta con correo contraseña para entrar a tu administrador o encontrar tu tienda fácilmente." />
						<div className="fields">
							<div className="item">
								<Text_input label="Nombre (*)" name="first_name" type="text" placeholder="Nombre" required={true} />
							</div>
							<div className="item">
								<Text_input label="Apellido (*)" name="last_name" type="text" placeholder="Apellido" required={true} />
							</div>
							<div className="item">
								<PhoneNumber_input callBackIsValid={setCallBackIsValid} label="Teléfono (*)" name="phone" placeholder="Teléfono" required={true} />
							</div>
							<div className="item">
								<TextField_with_icon label="Correo electrónico (*)" name="email" type="email" placeholder="Correo electrónico" required={true} />
							</div>
							<div className="item">
								<TextField_password label="Contraseña (*)" name="password" placeholder="Contraseña" required={true} action={handlePassword} />
							</div>

							<div className="item">
								{passwordConfirmation === false ? (
									<TextField_password error helperText="Las contraseñas tienen que ser iguales" label="Confirmar contraseña (*)" name="password_confirmation" placeholder="Confirmar contraseña" passwordConfirmation={passwordConfirmation} action={handlePasswordConfirmation} required={true} />
								) : (
									<TextField_password label="Confirmar contraseña (*)" name="password_confirmation" placeholder="Confirmar contraseña" action={handlePasswordConfirmation} passwordConfirmation={passwordConfirmation} required={true} />
								)}
							</div>
							<div className="item">
								<Select_input label="Selecciona el idioma de tu tienda (*)" name="language" options={languages.data} optionTitle={"name"} required={true} />
							</div>
						</div>
					</div>
				</div>
				{/* --background image-- */}
				<picture className="sectionBgImage">
					<source media="(max-width:575px)" srcSet={mobile_bgImage} />
					<img src={bgImage} alt="Flowers" />
				</picture>
				{/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
			</div>
		</div>
	);
}

function StoreSetup({ whatsappCheck, handleWhatsappCheck, ImageTheme, setImageTheme, setStoreNameValid }) {
	return (
		<div className="section forms_section Store_setUp_form_section">
			<div className="container">
				<div className="forms_sectionContent infoBoxContent">
					<div className="formDiv">
						<Heading text="CONFIGURACIÓN DE LA TIENDA" />
						<Text classText="formPage_subHeadings" text="Selecciona el nombre que quieres ponerle a tu tienda y los medios de comunicación que darás a tus clientes para que se pongan en contacto contigo, asesorar sus ventas o abrir nuevas tiendas." />
						<div className="fields">
							<div className="item">
								<InputVerifyStore label="Nombre (*)" name="store_name" type="text" placeholder="Nombre de la tienda" setStoreNameValid={setStoreNameValid} required={true} />
							</div>
							<div className="item">
								<TextInputMaxLength label="Iniciales de la tienda (*)" pattern="[a-zA-Z]*" maxlength="3" name="store_name_initials" type="text" placeholder="Iniciales Max . 3" required={true} />
							</div>

							<div className="item">
								<TextField_with_icon label="Correo electrónico empresarial (*)" name="store_email" type="email" placeholder="Correo electrónico" icon={<ErrorIcon />} popoverText={"Correo que los clientes utilizarán para contactarte"} required={true} />
							</div>
							<div className="item">
								<PhoneNumber_input label="Teléfono empresarial (*)" name="store_phone" placeholder="Teléfono" icon={<ErrorIcon />} popoverText={"Teléfono que los clientes utilizarán para contactarte"} required={true} />
								<FormGroup>
									<FormControlLabel control={<Checkbox name="whatsapp_check" checked={whatsappCheck} onChange={handleWhatsappCheck} />} label="Añadir enlace de WhatsApp" />
								</FormGroup>
							</div>
						</div>
						<StoreThemeSetup ImageTheme={ImageTheme} setImageTheme={setImageTheme} />
					</div>
				</div>
				{/* --background image-- */}
				<picture className="sectionBgImage">
					<source media="(max-width:575px)" srcSet={mobile_bgImage} />
					<img src={bgImage} alt="Flowers" />
				</picture>
				{/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
			</div>
		</div>
	);
}

function CompanyData({handleRadioCompany, radioCompany}) {
	return (
		<div className="section forms_section Company_data_form_section">
			<div className="container">
				<div className="forms_sectionContent infoBoxContent">
					<div className="formDiv">
						<Heading text="DATOS DE LA COMPAÑIA" />
						<Text classText="formPage_subHeadings" text="En caso te registres como parte de una empresa, Escribe nombre y su tax id, de lo contrario omite la pregunta seleccionando NO." />
						<div className="SelectBox">
							<Text text="¿Tienes una compañía registrada?" />
							<RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" defaultValue="no" name="company" required sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "1rem 0 0 0" }}>
								<FormControlLabel value="yes" control={<Radio />} label="Si" onChange={handleRadioCompany} />
								<FormControlLabel value="no" control={<Radio />} label="No" onChange={handleRadioCompany} />
							</RadioGroup>
						</div>
						{radioCompany === "yes" ? (
							<div className="fields">
								<div className="item">
									<Text_input label="Nombre de la compañía (*)" name="company_name" type="text" placeholder="Nombre de la compañia" />
								</div>
								<div className="item">
									<Text_input label="Tax id / ENI (*)" name="tax_id" type="text" placeholder="Tax id / ENI" />
								</div>
							</div>
						) : (
							""
						)}
					</div>
				</div>
				{/* --background image-- */}
				<picture className="sectionBgImage">
					<source media="(max-width:575px)" srcSet={mobile_bgImage} />
					<img src={bgImage} alt="Flowers" />
				</picture>
				{/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
			</div>
		</div>
	);
}

function AdressInformation({ setsocialOption, socialOption, handleOpenModal, openModal, handleCloseModal }) {
	return (
		<div className="section forms_section Address_information_form_section">
			<div className="container">
				<div className="forms_sectionContent infoBoxContent">
					<div className="formDiv">
						<Heading text="INFORMACIÓN DE DIRECCION" />
						<Text classText="formPage_subHeadings" text="Completa tus datos básicos y crea una cuenta con correo contraseña para entrar a tu administrador o encontrar tu tienda fácilmente." />
						<div className="fields">
							<div className="item">
								<CountrySelect label="País (*)" name="country" type="text" placeholder="Selecciona un país" required={true} />
								{/* <Select_input label="País (*)" name="country" placeholder="Selecciona un país" /> */}
							</div>
							<div className="item">
								<Text_input label="Provincia (*)" name="province" type="text" placeholder="Provincia" required={true} />
							</div>

							<div className="item">
								<Text_input label="Ciudad (*)" name="city" type="text" placeholder="Ciudad (*)" required={true} />
							</div>
							<div className="item">
								<Text_input label="Código Zip (*)" name="zip_code" type="text" placeholder="Código Zip" required={true} />
							</div>
						</div>
						<div className="location_fields">
							<div className="item">
								<Location_get label="Dirección 1 (*)" name="address_line1" placeholder="Dirección" required={true} />
							</div>
							<div className="item">
								<Location_get label="Dirección 2" name="address_line2" placeholder="Dirección 2" />
							</div>
						</div>
					</div>
					{/* ---Social_option--- */}
					<Social_option setsocialOption={setsocialOption} socialOption={socialOption} />
					{/* ---//Social_option--- */}
					<Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }} name="email_consent" defaultChecked/>
						<Typography>Acepto suscribirme al newsletter.</Typography>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }} name="sms_consent" defaultChecked/>
						<Typography>Acepto recibir mensajes de texto con las novedades de la tienda.</Typography>
					</Box>
					{/* ---agree checkbox--- */}
					<Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
						<Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
							<Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }} required />
							<Typography>Acepto términos y condiciones (*)</Typography>
						</Box>
						<Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
							<Box
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									width: "80vw",
									height: "70vh",
									bgcolor: "#fff",
									border: "none",
									boxShadow: 24,
									p: 4,
									overflowY: "auto",
								}}>
								<div className="form-modal">
									<div>
										<h5>Terminos y Condiciones</h5>
									</div>
									<div>
										<div>
											<ul className="politicas-head">
												<h6>GENERALES</h6>
												<p>Las ganancias por las ventas se verán reflejadas de la siguiente forma:</p>
												<p>Sobre todas las ventas de tus tiendas, tendrás hasta el 30% de ganancia y adicional, tendrás un 5% sobre las ventas de las tiendas de tus referidos directos.*</p>
												<p>Recibirás las comisiones de las ventas de tus referidas mientras tu tienda se encuentre activa o con órdenes durante ese periodo.</p>
												<p>
													Ventadirekta solicita los datos de tu TDC (Tarjeta de crédito)solo en los casos de activar el sistema de muestras o devoluciones dentro de tu tienda.&nbsp;
													<br />
													En el primer caso, al estar inscrito y no cumplir con las políticas de tiempos de retorno o calidad de los productos, se hace un cobro del 50% del costo de las prendas enviadas. Para el segundo escenario, se descuenta el valor de la ganancia consignada correspondiente a las devoluciones efectuadas de ventas el mes anterior.
												</p>
												<p>
													A tu cuenta de Paypal son consignados los primeros 10 días de cada mes las ganancias de las ventas realizadas el mes pasado. En caso de recibir una o varias devoluciones, debemos descontar de tu extracto actual el valor de las prendas regresadas y, verás en tu extracto el campo “last month balance“ con este valor.&nbsp;
													<br />
													Tus ganancias del mes en curso puede cubrirlo; sin embargo, en caso de no ser así, tiene dos meses para hacerlo. Al terminar este periodo y el valor aún está pendiente, este rubro será cobrado a tu tarjeta.
												</p>
												<p>*Aplica solo para los usuarios que se registren a través del link de referidos dentro de la tienda de la embajadora.</p>
												<h6>Formulario W9:</h6>
												<p>Con la información proporcionada en este formulario, la empresa elabora el formulario 1099-MISC, el cual notifica el monto total pagado al proveedor el año anterior. En caso de ser un monto superior o igual a $600 dólares, se envía copia de este formulario tanto al proveedor como a la IRS.</p>
												<h6>Programa de muestras:</h6>
												<p>
													Prestamos a nuestras embajadoras 3 productos de muestra para que conozcan y presenten a sus clientes por un periodo de una semana. Al culminar este tiempo, las prendas deben ser regresadas al almacén para recibir otras 3 referencias diferentes.&nbsp;
													<br />
													Las prendas serán elegidas por la embajadora. Los envíos y retornos no tienen ningún costo.
												</p>
												<p>Si eres una de las embajadoras que desea disfrutar de nuestro programa de fajas, debes cumplir con lo siguiente:</p>
												<p>
													- Tendrás una semana para generar contenido en las redes sociales (Instagram, Facebook, Tiktok, etc)&nbsp;
													<br />- Generar contenido de vídeo y fotos mostrando la prenda, explicando sus beneficios e ilustrando el antes y después de su uso, cómo utilizarla en el día a día, y todas las ideas que tengas.&nbsp;
													<br />- Mencionar tu tienda e incluir el link directo a ella desde todas las redes
												</p>
												<p>Para guiarte en el proceso de creación de contenido sobre los productos cuentan con VDK Academy, la academia de Ventadirekta con tutoriales y capacitaciones.</p>
											</ul>
										</div>
									</div>
								</div>
							</Box>
						</Modal>
						<Button onClick={handleOpenModal}>
							<DescriptionIcon sx={{ color: "var(--primary-color)" }} />
						</Button>
					</Box>
					{/* ---//agree checkbox--- */}
				</div>
				{/* --background image-- */}
				<picture className="sectionBgImage">
					<source media="(max-width:575px)" srcSet={mobile_bgImage} />
					<img src={bgImage} alt="Flowers" />
				</picture>
				{/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
			</div>
		</div>
	);
}

function StoreThemeSetup({ ImageTheme, setImageTheme }) {
	// --images list---
	const imageList = [
		{
			id: "theme_1",
			img: img1,
		},
		{
			id: "theme_2",
			img: img2,
		},
		{
			id: "theme_3",
			img: img3,
		},
		{
			id: "theme_4",
			img: img4,
		},
		{
			id: "theme_5",
			img: img5,
		},
		{
			id: "theme_6",
			img: img6,
		},
	];

	return (
		<div className="imageContent">
			<MGrid container columnSpacing={5}>
				<MGrid item xs={6}>
					<div className="imageSlider">
						<p>Selecciona un tema para aplicarlo</p>
						<MGrid container columnSpacing={1}>
							{imageList.map((e, i) => {
								return (
									<MGrid item xs={4} key={i}>
										<img src={e.img} className={ImageTheme === e.id ? "active" : ""} key={i} alt="theme" onClick={(item) => setImageTheme(e.id)} />
									</MGrid>
								);
							})}
						</MGrid>
					</div>
				</MGrid>
				<MGrid item xs={6}>
					<div className="imageBanner">
						<img src={imageList.find((item) => item.id === ImageTheme).img} alt="theme" />
					</div>
				</MGrid>
			</MGrid>
		</div>
	);
}

function TermsModal({ openModal, handleCloseModal }) {
	const styleModal = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "80vw",
		height: "70vh",
		bgcolor: "#fff",
		border: "none",
		boxShadow: 24,
		p: 4,
		overflowY: "auto",
	};

	<Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
		<Box sx={styleModal}>
			<div class="modal-referral">
				<div>
					<h5>Terminos y Condiciones</h5>
				</div>
				<div>
					<div>
						<ul class="politicas-head">
							<h6>GENERALES</h6>
							<p>Las ganancias por las ventas se verán reflejadas de la siguiente forma:</p>
							<p>Sobre todas las ventas de tus tiendas, tendrás hasta el 30% de ganancia y adicional, tendrás un 5% sobre las ventas de las tiendas de tus referidos directos.*</p>
							<p>Recibirás las comisiones de las ventas de tus referidas mientras tu tienda se encuentre activa o con órdenes durante ese periodo.</p>
							<p>
								Ventadirekta solicita los datos de tu TDC (Tarjeta de crédito)solo en los casos de activar el sistema de muestras o devoluciones dentro de tu tienda.&nbsp;
								<br />
								En el primer caso, al estar inscrito y no cumplir con las políticas de tiempos de retorno o calidad de los productos, se hace un cobro del 50% del costo de las prendas enviadas. Para el segundo escenario, se descuenta el valor de la ganancia consignada correspondiente a las devoluciones efectuadas de ventas el mes anterior.
							</p>
							<p>
								A tu cuenta de Paypal son consignados los primeros 10 días de cada mes las ganancias de las ventas realizadas el mes pasado. En caso de recibir una o varias devoluciones, debemos descontar de tu extracto actual el valor de las prendas regresadas y, verás en tu extracto el campo “last month balance“ con este valor.&nbsp;
								<br />
								Tus ganancias del mes en curso puede cubrirlo; sin embargo, en caso de no ser así, tiene dos meses para hacerlo. Al terminar este periodo y el valor aún está pendiente, este rubro será cobrado a tu tarjeta.
							</p>
							<p>*Aplica solo para los usuarios que se registren a través del link de referidos dentro de la tienda de la embajadora.</p>
							<h6>Formulario W9:</h6>
							<p>Con la información proporcionada en este formulario, la empresa elabora el formulario 1099-MISC, el cual notifica el monto total pagado al proveedor el año anterior. En caso de ser un monto superior o igual a $600 dólares, se envía copia de este formulario tanto al proveedor como a la IRS.</p>
							<h6>Programa de muestras:</h6>
							<p>
								Prestamos a nuestras embajadoras 3 productos de muestra para que conozcan y presenten a sus clientes por un periodo de una semana. Al culminar este tiempo, las prendas deben ser regresadas al almacén para recibir otras 3 referencias diferentes.&nbsp;
								<br />
								Las prendas serán elegidas por la embajadora. Los envíos y retornos no tienen ningún costo.
							</p>
							<p>Si eres una de las embajadoras que desea disfrutar de nuestro programa de fajas, debes cumplir con lo siguiente:</p>
							<p>
								- Tendrás una semana para generar contenido en las redes sociales (Instagram, Facebook, Tiktok, etc)&nbsp;
								<br />- Generar contenido de vídeo y fotos mostrando la prenda, explicando sus beneficios e ilustrando el antes y después de su uso, cómo utilizarla en el día a día, y todas las ideas que tengas.&nbsp;
								<br />- Mencionar tu tienda e incluir el link directo a ella desde todas las redes
							</p>
							<p>Para guiarte en el proceso de creación de contenido sobre los productos cuentan con VDK Academy, la academia de Ventadirekta con tutoriales y capacitaciones.</p>
						</ul>
					</div>
				</div>
			</div>
		</Box>
	</Modal>;
}

export default Forms;
