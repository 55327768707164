import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import siteLogo from '../../../Images/siteLogoWhite.png'

function Footer() {
    return (
        <div className="section footerSection">
            <div className="container">
                <div className="footerContent">
                    {/* -- */}
                    <div className="item siteLogo">
                        <NavLink to="/">
                            <img src={siteLogo} alt="#" />
                        </NavLink>
                    </div>
                    {/* -- */}
                    <div className="item help">
                        <p className="heading">AYUDA</p>
                        <ul>
                            {/* <li><a href="/">Nosotros</a></li> */}
                            <li><Link to="/privacy-politics">Políticas de privacidad</Link></li>
                            <li><Link to="/terms-conditions">Términos y condiciones</Link></li>
                            <li><Link to="/returns-changes">Política de devolución</Link></li>
                            <li><Link to="/faqs">Preguntas frecuentes</Link></li>
                        </ul>
                    </div>

                    {/* -- */}
                    <div className="item info">
                        <p className="heading">INFORMACIÓN</p>
                        <ul>
                            <li><Link to="/want-to-sell">Quiero vender</Link></li>
                            <li><Link to="https://vdk-oficial.ventadirekta.mx/" target="_blank">Quiero comprar</Link></li>
                            <li><Link to="/academy">Ventadirekta Academy</Link></li>
                        </ul>
                    </div>

                    {/* -- */}
                    <div className="item contact">
                        <ul>
                            <p className="heading">CONTACTANOS</p>
                            <li><Link to="https://wa.me/+525541653387" target="_blank">+52 55 4165 3387</Link></li>

                        </ul>
                        <div className="socialContact">
                            <ul>
                                <li><Link to="https://instagram.com/ventadirekta.mx?utm_medium=copy_link" target="_blank"><InstagramIcon /> INSTAGRAM</Link></li>
                                <li><Link to="https://www.facebook.com/Ventadirekta.mx" target="_blank"><FacebookIcon />  FACEBOOK</Link></li>
                                <li><Link to="https://www.youtube.com/@ventadirektamx8488" target="_blank"><YouTubeIcon />  YOUTUBE</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer