import React, { useState } from 'react'
import Text_input from '../../../CommonComponent/Inputs/Text_input'
import { TextField } from '@mui/material'
import MainButton from '../../../CommonComponent/Button/MainButton'
import EastIcon from '@mui/icons-material/East';
import PhoneNumber_input from "../../../CommonComponent/Inputs/PhoneNumber_input";
//--elements--
import pentagonElement from '../../../../Images/elements/pentagon.svg'

import { toast } from "react-toastify";

const validProductionAdminUrl = function (url = "") {
	if (process.env.REACT_APP_NODE_ENV === "TEST") {
		return `http://localhost:3030${url}`;
	} else {
		return `https://admin.ventadirekta.mx${url}`;
	}
};

export default function ContactForm() {

    const [callBackIsValid, setCallBackIsValid] = useState("");

	const handleSubmit = async (event) => {
		event.preventDefault();

		let data = {
			name: event.target.name.value,
			email: event.target.email.value,
			phone: event.target.phone.value,
			comentaries: event.target.comentaries.value,
			zip_code: '',
		};
        
        if (callBackIsValid) {
            
            try {
                const result = await fetch(validProductionAdminUrl("/api/v1/contact-page-form"), {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
                toast.success("Mensaje enviado con éxito");
            } catch (e) {
                console.log(e);
                toast.error("Ocurrió un error al enviar el mensaje.");
            }
        }else{

            toast.error("El teléfono es inválido");
        }
	};

    return (
        <div className="section contactFormSection">
            <div className="container contactFormContainer">
                <form onSubmit={handleSubmit}>
                    <div className="box">
                        <div className="fields">
                            <div className="field1">
                                <Text_input labelText="Nombre" name="name" type="text" required={true}/>
                            </div>
                            <div className="field2">
                                <PhoneNumber_input callBackIsValid={setCallBackIsValid} Inlabel="Teléfono" name="phone" placeholder="Teléfono" required={true} />
                            </div>
                            <div className="field3">
                                <Text_input name="email" labelText="Correo electrónico" type="text" required={true}/>
                            </div>
                            <div className="field4">
                                <TextField
                                    fullWidth
                                    label="Comentarios"
                                    multiline
                                    rows={10}
                                    name="comentaries"
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="submitBtn">
                            <MainButton text="ENVIAR" startIcon={<EastIcon />} type="submit" />
                        </div>
                        {/* --- */}
                        <div className="elementsDiv">
                            <img src={pentagonElement} alt="element" className="frist" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
