import React, { useState } from "react";
import ReflectHeading from "../../../CommonComponent/Typography/ReflectHeading";
import ReflectHeading2 from "../../../CommonComponent/Typography/ReflectHeading2";
import bgImage from "../../../../Images/HomePage/CategoriesSection/bg.png";
import mobile_bgImage from "../../../../Images/HomePage/CategoriesSection/mobileBg.png";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Scrollbar } from "swiper";
import useAnimations from "../../../../Auth/useAnimations";
import Categori_card from "./Categori_card";
// ---images import---
import img1 from "../../../../Images/HomePage/CategoriesSection/Rectangle 1226.webp";
import img2 from "../../../../Images/HomePage/CategoriesSection/Rectangle 1227.webp";
import img3 from "../../../../Images/HomePage/CategoriesSection/Rectangle 1229.webp";
import img4 from "../../../../Images/HomePage/CategoriesSection/Rectangle 1230.webp";
import img5 from "../../../../Images/HomePage/CategoriesSection/Rectangle 1231.webp";
import downArrowImage from "../../../../Images/DownArrow_image.png";

function Categorias() {
	// --add custome pagination bullets and style by css so if need some change it style. check in css file--
	const pagination = {
		clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + (index + 1) + "</span>";
		},
	};

	// ---categories content list---
	const categoriesData = [
		{
			id: 1,
			img: img1,
			name: "jeans",
			link: "/",
		},
		{
			id: 2,
			img: img2,
			name: "fajas",
			link: "/",
		},
		{
			id: 3,
			img: img3,
			name: "shorts",
			link: "/",
		},
		{
			id: 4,
			img: img4,
			name: "bra",
			link: "/",
		},
		// {
		// 	id: 5,
		// 	img: img5,
		// 	name: "health & beauty",
		// 	link: "/",
		// },
	];
	return (
		<>
			<div className="section">
				<div className="container categoriesSection">
					{/* -- */}
					<div className="sliderContainer">
						<div className="sliderHeading">
							<span>jeans</span>
							<img src={downArrowImage} alt="downArrowImage" />
						</div>
						<div className="categorySlider">
							<div className="heading">
								<ReflectHeading2 boldText="CATEGORIAS" />
							</div>
							<Swiper slidesPerView={"auto"} spaceBetween={15} pagination={pagination} modules={[Pagination]} className="categorySlider_slider swiper">
								{categoriesData &&
									categoriesData.map((e, i) => {
										return (
                                            <SwiperSlide key={e.id}>
                                                <Categori_card name={e.name} img={e.img} link={e.link} />
                                            </SwiperSlide>
										);
									})}
							</Swiper>
						</div>
					</div>
					{/* --background image-- */}
					{/* --background image-- */}
					<picture className="sectionBgImage">
						<source media="(max-width:575px)" srcSet={mobile_bgImage} />
						<img src={bgImage} alt="Flowers" />
					</picture>
					{/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
				</div>
			</div>
		</>
	);
}

export default Categorias;
