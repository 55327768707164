import React, { useState } from "react";
import MainButton from "../../../CommonComponent/Button/MainButton";
import Text_input from "../../../CommonComponent/Inputs/Text_input";
import PhoneNumber_input from "../../../CommonComponent/Inputs/PhoneNumber_input";
import EastIcon from "@mui/icons-material/East";
import Text from "../../../CommonComponent/Typography/Text";

import { toast } from "react-toastify";

const validProductionAdminUrl = function (url = "") {
	if (process.env.REACT_APP_NODE_ENV === "TEST") {
		return `http://localhost:3030${url}`;
	} else {
		return `https://admin.ventadirekta.mx${url}`;
	}
};

function MoreInfoForm_secondDiv() {

    const [callBackIsValid, setCallBackIsValid] = useState("");

	const handleSubmit = async (event) => {
		event.preventDefault();

		let data = {
			name: event.target.name.value,
			email: event.target.email.value,
			phone: event.target.phone.value,
			zip_code: event.target.zip_code.value,
		};
        
        if (callBackIsValid) {
            
            try {
                const result = await fetch(validProductionAdminUrl("/api/v1/contact-form"), {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
                toast.success("Mensaje enviado con éxito");
            } catch (e) {
                console.log(e);
                toast.error("Ocurrió un error al enviar el mensaje.");
            }
        }else{

            toast.error("El teléfono es inválido");
        }
	};
    
	return (
		<div className="secondDiv">
			<Text classText="heading" boldText="QUIERO MÁS" text="INFORMACIÓN" />
			<Text classText="pera" text="Si aún tienes alguna duda y quieres saber más sobre nosotros, completa solo tus datos de contacto en el botón de registro y uno de nuestros asesores se pondrá en contacto contigo para explicarte cómo funciona y darte toda la información." />
			<div className="formDiv">
				<form onSubmit={handleSubmit}>
					<div className="form">
						<div className="fields">
							{/* -- */}
							<div className="item">
								<Text_input labelText="Nombre" name="name" type="text" required={true} />
							</div>
							{/* -- */}
							<div className="item">
								<Text_input labelText="Correo electrónico" name="email" type="text" required={true} />
							</div>
							{/* -- */}
							<div className="item">
								<PhoneNumber_input callBackIsValid={setCallBackIsValid} Inlabel="Teléfono" name="phone" placeholder="Teléfono" required={true} />
								{/* <Text_input labelText="Telefono" name="phone" type="tel" /> */}
							</div>
							{/* -- */}
							<div className="item">
								<Text_input labelText="Zip Code" name="zip_code" type="number" required={true} />
							</div>
						</div>
						<div className="submitButton">
							<MainButton text="REGISTRARME" startIcon={<EastIcon />} type="submit" />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default MoreInfoForm_secondDiv;
