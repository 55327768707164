import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams, useNavigate } from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";
import ReflectHeading from '../../CommonComponent/Typography/ReflectHeading'

//--elements--
import starElement from '../../../Images/elements/star.svg'
//--bglines--
import linesBg from '../../../Images/Blogs/BlogSectionBgLines.svg'

export default function BlogSection() {
    const url = useParams();
    const navigate = useNavigate()

    // console.log(url)
    
    // --paginationValue--
    const [paginationValue, setpaginationValue] = useState(1);
    const [load, setLoad] = useState(false);
	const [blogData, setBlogData] = useState();
	const [error, setError] = useState(false);

    const validProductionAdminUrl = function (url = "") {
		if (process.env.REACT_APP_NODE_ENV === "TEST") {
			return `http://localhost:3030${url}`;
		} else {
			return `https://admin.ventadirekta.mx${url}`;
		}
	};

    useEffect(() => {
		setLoad(false);
		getData();
	}, []);

    const getData = async () => {

		try {
			const res = await fetch(validProductionAdminUrl(`/api/v1/blogs`));
			const blog_data = await res.json();
            
			setBlogData(blog_data);
            
			setError(false);
		} catch (e) {
            console.log(e);
			setError(true);
		}
		setLoad(true);
	};
    
    if (load) {
        
        return (
            <div className="section blogSection">
                <div className="container blogContainer">
                    <div className="heading">
                        <ReflectHeading boldText="BLOG" />
                    </div>
                    {/* -------------- */}
                    <div className="BlogCards">
                        {/* --- */}
                        {JSON.parse(blogData.articles).slice(paginationValue * 12 - 12, paginationValue * 12).map((e, index) => (
                            <div className="blogCard" key={e.index} onClick={() => {
								navigate(`/blogs/${e.id}`);
							}}>
                                <LazyLoadImage src={e.image.src} alt={e.title} />
                                <p className="title">{e.title}</p>
                                {/* <p className="des">{e.review}</p> */}
                                <p className='data'>{e.date_format}</p>
                            </div>
                        ))}
                        {/* --- */}
                    </div>
                    {/* ------------- */}
                    <div className="paginationNum">
                        <Pagination count={parseInt(JSON.parse(blogData.articles).length / 12) + 1} shape="rounded" hidePrevButton hideNextButton onChange={(e) => setpaginationValue(Number(e.currentTarget.textContent))} />
                        <span>Siguiente</span>
                    </div>
                    {/* ---elements--- */}
                    <div className="elementsDiv">
                        <img src={starElement} alt="element" className="first" />
                    </div>
                    {/* --background image-- */}
                    <picture className='sectionBgImage'>
                        {/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
                        <img src={linesBg} alt="lines" />
                    </picture>
                </div>
            </div>
        )
    }else{
       return (
        <div style={{ width: "100%", height: "100vh", backgroundColor: "#fff", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress style={{ color: "#000" }} />
        </div>
       )
    }
}
